<template>
  <div>
    <v-overlay
      :z-index="100"
      :value="isLoading"
    >
      <v-progress-circular
        v-show="isLoading"
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-app-bar
      id="home-app-bar"
      app
      :color="$vuetify.theme.isDark ? 'black' : 'white'"
      elevation="1"
      elevate-on-scroll
      height="80"
      extension-height="80"
    >
      <template
        v-slot:extension
        class="extension-style"
      >
        <v-row>
          <v-col>
            <div
              :class="
                $vuetify.breakpoint.smAndDown
                  ? 'flex-column align-start'
                  : 'align-center'
              "
              class="d-flex flex-wrap"
            >
              <v-btn
                style="background-color: black; height: 100%; color: #d4af37; width: 100%; padding: 20px !important"
                class="pa-3"
                :class="
                  $vuetify.breakpoint.smAndDown
                    ? 'text-subtitle-2'
                    : 'text-h5'
                "
                outlined
                @click="$router.push({ name: 'Jazz' })"
              >
                <span v-html="'\u266C'" /><b>Jazz Weekend Trails and Gigs</b><span v-html="'\u266C'" />
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </template>
      <base-img
        :src="$vuetify.theme.isDark
          ? require(`@/assets/icon_black.png`)
          : require(`@/assets/icon_white.png`)"
        class="hidden-xs-only"
        contain
        max-width="80"
        width="100%"
        @click="$router.push({ name: 'Home' })"
      />

      <base-img
        :src="
          $vuetify.theme.isDark
            ? require(`@/assets/LogoText.png`)
            : require(`@/assets/LogoTextWhite.png`)
        "
        contain
        max-width="200"
        width="100%"
        @click="$router.push({ name: 'Home' })"
      />

      <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
          background-color="transparent"
        >
          <v-tab
            v-for="(name, i) in items"
            :key="i"
            :to="{ name }"
            :exact="name === 'Home'"
            :ripple="false"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ name }}
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="items"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },

    data: () => ({
      appLoaded: false,
      drawer: null,
      items: ['Home', 'Jazz', 'Trails', 'Discover', 'A To Z', 'About', 'Contact'],
    }),
    computed: {
      ...mapGetters(['isLoading']),
    },
  }
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
